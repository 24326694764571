import {Period} from '../../../pages/report/context/ReportProvider'
import SetupAxios from '../../../../setup/axios/SetupAxios'
import {PropsData} from '../../../../_metronic/partials/widgets/tables/call-tracking/ts/Types'
import {API_URL} from '../../all-pages'

// const fetchDashboardData = async (endpoint: string, period: Period, sortf: string, sorts: string) => {
//
//     const {start, end} = period
//
//     try {
//         const params = new URLSearchParams({
//             ...(period && {start, end}),
//             sortf, sorts
//         }).toString()
//         const response = await SetupAxios.get<PropsData[]>(`${API_URL}${endpoint}?${params}`)
//         console.log(response.data)
//         return response.data
//     } catch (e) {
//         console.error('Error fetching call log data:', e);
//         return [];
//     }
//
// }

interface GetInterface<T> {
    endpoint: string
    period: Period
    sortKey?: string
    sortOrder?: string
}

export async function Get<T>({endpoint, period, sortKey, sortOrder}: GetInterface<T>) {

    const {start, end} = period

    try {
        const params = new URLSearchParams({
            ...(period && {start, end}),
            ...(sortKey && {sortf: sortKey}),
            ...(sortOrder && {sorts: sortOrder})
        }).toString()

        const response = await SetupAxios.get<PropsData[]>(`${API_URL}${endpoint}?${params}`)
        return response.data
    } catch (e) {
        console.error('Error fetching call log data:', e);
    }
}