import {CustomProvider, DateRangePicker} from 'rsuite'
import React, {Dispatch, FC, SetStateAction, useCallback, useEffect} from 'react'
import {LocalesRangeValue} from '../../Variables'
import subDays from 'date-fns/subDays'
import addDays from 'date-fns/addDays'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import addMonths from 'date-fns/addMonths'
import isAfter from 'date-fns/isAfter'
import './RangeValue.scss'
import {RangeType, ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {UseWindowSize} from '../../hooks'
import {useUser} from '../../../context/types'

interface PropsRangeValue {
    rangeValue: ValueType;
    setRangeValue: (value: ValueType) => void;
    loadingStatus: 'idle' | 'pending' | 'fulfilled' | 'rejected';
    setLoadingStatus: Dispatch<SetStateAction<'idle' | 'pending' | 'fulfilled' | 'rejected'>>;
    className?: string;
    title?: boolean;
}


const DateRange: FC<PropsRangeValue> = ({
                                            rangeValue,
                                            setRangeValue,
                                            loadingStatus,
                                            setLoadingStatus,
                                            className,
                                            title = true,
                                        }) => {

    const localeKey = 'fr_FR'
    const locale = LocalesRangeValue.find(item => item.key === localeKey)
    const {created_at} = useUser()

    const handle = useCallback(async (value: ValueType) => {
        setRangeValue(value)
        setLoadingStatus('pending')
    }, [setRangeValue, setLoadingStatus])

    useEffect(() => {
        if (loadingStatus === 'fulfilled') {
            const timer = setTimeout(() => setLoadingStatus('idle'), 500)
            return () => clearTimeout(timer)
        }
    }, [loadingStatus, setLoadingStatus])

    const RANGES: RangeType[] = [
        {label: 'Tous', value: [new Date(`${created_at}`), new Date()]},
        {label: 'Aujourd\'hui', value: [new Date(), new Date()]},
        {label: 'Hier', value: [addDays(new Date(), -1), addDays(new Date(), -1)]},
        {label: '7 derniers jours', value: [subDays(new Date(), 6), new Date()]},
        {label: '30 derniers jours', value: [subDays(new Date(), 29), new Date()]},
        {label: 'Ce mois-ci', value: [startOfMonth(new Date()), new Date()]},
        {
            label: 'Mois dernier',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        },
    ]
    const {innerWidth} = UseWindowSize()


    return (
        <div className={className}>

            {/* start::loading */}
            {loadingStatus === 'pending' && (
                <span className="me-3" data-kt-indicator="on">
                    <span className="indicator-progress p-2">
                        <span className="spinner-border spinner-border-sm align-middle" />
                    </span>
                </span>
            )}
            {/* end::loading */}

            {/*  start::DateRangePicker  */}
            <form className="form">
                {title && (
                    <span className="text-gray-600 fw-semibold fs-8 me-3">Choisissez une période</span>)}
                <CustomProvider locale={locale?.value}>

                    <DateRangePicker
                        ranges={RANGES}
                        placement={innerWidth >= 576 ? 'bottomEnd' : 'bottom'}
                        format="dd/MM/yyyy"
                        placeholder="jj/mm/aaaa ~ jj/mm/aaaa"
                        value={rangeValue}
                        onChange={handle}
                        onOk={handle}
                        style={{width: 250}}
                        disabledDate={date => isAfter(date, new Date())}
                        size="sm"
                        showOneCalendar={innerWidth < 576}
                    />

                </CustomProvider>
            </form>
            {/*  end::DateRangePicker  */}
        </div>
    )
}

export {DateRange}