import {FC, useCallback, useState} from 'react'
import {ChildrenInterface} from '../../../global'
import {
    NotificationsContext, NotificationsContextType,
    NotificationsInitialState,
    NotificationsResponse,
} from './types'
import SetupAxios from '../../../setup/axios/SetupAxios'
import {useQuery} from 'react-query'

const NOTIFICATIONS = process.env.REACT_APP_NOTIFICATIONS || 'notifications'

const fetchData = async (endpoint: string): Promise<NotificationsResponse> => {
    try {
        const response = await SetupAxios.get<NotificationsResponse>(endpoint)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error
    }
}

export const NotificationsProvider: FC<ChildrenInterface> = ({children}) => {

    const [notifications, setNotifications] = useState<Partial<NotificationsResponse>>(NotificationsInitialState);

    const { refetch } = useQuery('Notifications', () => fetchData(NOTIFICATIONS), {
        onSuccess: (data) => {
            setNotifications(data);
        },
    });

    // Create a callback to refresh data
    const refreshNotifications = useCallback(() => {
        console.log('hello')
        refetch();
    }, [refetch]);

    const contextValue: NotificationsContextType = {
        notifications,
        refreshNotifications,
    };

    return (
        <NotificationsContext.Provider value={contextValue}>
            {children}
        </NotificationsContext.Provider>
    )
}