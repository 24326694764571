import React from 'react'
import {Card2} from './component/Card2'
import {useReport} from './context/ReportProvider'

const Conversion = () => {

    const {callReceived, formsReceived, campagne: {countMessages, countDataContacts}} = useReport()

    const data = [
        {title: 'conversions', stats: null, img: null},
        callReceived.has_cleverPhone
            ? {title: 'appels', stats: callReceived.generalAppelRecu, img: '/media/icons/duotune/communication/com019.svg'}
            : {title: 'clics téléphone', stats: callReceived.click_sur_tel, img: '/media/icons/duotune/coding/cod0011.svg'},
        {title: 'formulaires', stats: formsReceived, img: '/media/icons/duotune/general/gen061.svg'},
        {title: 'contacts', stats: countDataContacts, img: '/media/icons/duotune/communication/com020.svg'},
    ]

    return (
        <div className="row p-5">
            {
                data.map(({title, stats, img}, index) => (
                    <div className="col-md-3 mb-xl-10" key={index}>
                        <Card2 title={title} stats={stats} svgIcon={img ? {type: 'duotune', path: img} : null} />
                    </div>
                ))
            }

        </div>
    )
}
export {Conversion}