import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useNotifications} from '../../../../app/modules/context/types'
import {stripHtml} from '../../../../app/pages/report/utils/functions'

const HeaderNotificationsMenu: FC = () => {

    const { notifications, refreshNotifications } = useNotifications();

    return (
        <div
            className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-400px"
            data-kt-menu="true"
        >
            <div
                className="d-flex flex-column bgi-no-repeat rounded-top"
                style={{background: `linear-gradient(112.14deg, rgb(12 147 225) 0%, rgb(225 0 109 / 43%) 100%)`}}
            >
                <h3 className="text-white fw-bold px-9 my-6">
                    Notifications <span className="fs-8 opacity-75 ps-3">24 reports</span>
                </h3>

                <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
                    <li className="nav-item">
                        <a
                            className="nav-link text-white opacity-75 opacity-state-100 pb-4"
                            data-bs-toggle="tab"
                            href="#kt_topbar_notifications_1"
                        >
                            SAV
                        </a>
                    </li>
                </ul>
            </div>

            <div className="tab-content">
                <div className="tab-pane fade show active" id="kt_topbar_notifications_1" role="tabpanel">
                    <div className="scroll-y mh-325px my-5 ps-4">
                        {notifications.ticket_message?.data.map((ticket, index) => (
                            <div key={`alert${index}`} className="d-flex flex-stack py-4">
                                <div className="d-flex align-items-center">
                                    <div className="mb-0 me-2">
                                        <Link to={`/sav/ticket/${ticket.id}`} className="fs-6 text-gray-800 text-hover-primary fw-bolder">
                                            Project Alice
                                        </Link>
                                        <p className="text-gray-400 fs-7">
                                            {stripHtml(ticket.message)}
                                        </p>
                                    </div>
                                </div>

                                <span className="badge badge-light fs-8">{ticket.created_at}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export {HeaderNotificationsMenu}
