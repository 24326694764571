import {Card, DateRange, Table, useClickInside, useClickOutside} from '../../modules/all-pages'
import {ModalSav} from './Modal'
import {Link} from 'react-router-dom'
import React, {ChangeEvent, FC, useMemo, useRef, useState} from 'react'
import {useSavContext} from './context/SavContext'
import {Ticket, Ticket_Status} from './types'
import {Tooltip, Whisper} from 'rsuite'
import {KTSVG} from '../../../_metronic/helpers'

import {SortKeysForm,SortKeysTicketSav} from "../../../_metronic/partials/widgets/tables/call-tracking/ts/Types";
import {SortButton} from "../../../_metronic/partials/widgets/tables/web-formulaires/SortButton";
import {Functions} from "../../../_metronic/partials/widgets/tables/web-formulaires/ts";

const tableHead = (
    <>
        <th className="ps-4 fw-bolder text-muted bg-light min-w-150px text-capitalize">Date</th>
        <th className="fw-bolder text-muted bg-light min-w-200px text-capitalize">Sujet</th>
        <th className="fw-bolder text-muted bg-light min-w-200px text-capitalize">Services</th>
        <th className="fw-bolder text-muted bg-light min-w-200px text-capitalize">Détails</th>
        <th className="fw-bolder text-muted bg-light text-capitalize">Description</th>
        <th className="fw-bolder text-muted bg-light min-w-100px text-capitalize">Status</th>
        <th />
    </>
)


const headers: { key: SortKeysTicketSav, label: string, is_sort: boolean }[] = [
    {key: 'created_at', label: 'Date', is_sort: true},
    {key: 'subject', label: 'Sujet', is_sort: true},
    {key: 'product', label: 'Services', is_sort: false},
    {key: 'assign', label: 'Détails', is_sort: false},
    {key: 'description', label: 'Description', is_sort: true},
    {key: 'status', label: 'Status', is_sort: false},
]


interface TransformedTicket extends Omit<Ticket, 'status'> {
    status: Ticket_Status;
}

const Tickets = () => {

    const {
        data,
        rangeValue,
        setRangeValue,
        loadingStatus,
        setLoadingStatus,
    } = useSavContext()

    const transformedData = useMemo(() => {
        if (data) {
            const statusMap = new Map(data.statusArray?.map(status => [status.name, status]))
            const transformedTickets = data.tickets?.map(ticket => ({
                ...ticket,
                status: statusMap.get(ticket.status) || {
                    id: 0,
                    name: ticket.status,
                    color: 'default',
                },
            }))
            return {tickets: transformedTickets}
        }
        return undefined
    }, [data])

    const [selectedStatus, setSelectedStatus] = useState('')
    const StatusRef = useRef(null)

    const TicketRow: FC<{ticket: TransformedTicket}> = ({ticket}) => (
        <tr key={ticket.id}>
            <td className="ps-4">{ticket.created_at}</td>
            <td>
                <Link to={`/sav/ticket/${ticket.id}`} className="text-dark fw-bolder text-hover-primary fs-7 fs-sm-6">
                    {ticket.subject}
                </Link>
            </td>
            <td>{ticket.product}</td>
            <td>{ticket.assign}</td>
            <td><DropDown description={ticket.description} /></td>
            <td>
                <div className={`badge badge-light-${ticket.status.color} fs-8 fw-bolder`}>
                    {ticket.status.name}
                </div>
            </td>
            <td className="text-center">
                <Whisper placement="top" controlId="control-id-click" trigger="hover"
                         speaker={(<Tooltip>Aperçu</Tooltip>)}
                >
                    <Link to={`/sav/ticket/${ticket.id}`} className="btn btn-sm btn-icon btn-active-light-success">
                        <i className="bi bi-eye-fill fs-3 text-success" />
                    </Link>
                </Whisper>
            </td>
        </tr>
    )

    const filteredData = useMemo(
        () => {
            if (transformedData) {
                if (selectedStatus === '') return transformedData.tickets
                return transformedData.tickets?.filter(ticket => ticket.status.name === selectedStatus)
            }
            return []
        },
        [transformedData, selectedStatus],
    )

    const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedStatus(event.target.value)
    }

    const tableBody = (
        <>
            {filteredData?.map(ticket => (
                <TicketRow key={ticket.id} ticket={ticket} />
            ))}
        </>
    )
    return (
        <Card id={'sav'} title={'SAV'}>
            <div className="row mb-5">
                <div className="col-12 d-flex justify-content-end align-items-center">
                    <ModalSav />
                </div>
            </div>
            <div className="row d-flex justify-content-between align-items-center mb-5">
                <div className="col-4">
                    <div className="d-flex align-items-center">
                        <label htmlFor="category" className="text-gray-400 fs-8 fs-sm-7 me-2 me-sm-">Statut</label>
                        <select name="" id="" ref={StatusRef} onChange={handleStatusChange}
                                className="form-select form-select-sm form-select-solid">
                            <option value="">Tous</option>
                            {data?.statusArray?.map((status, index) => (
                                <option value={status.name} key={index}>{status.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-4">
                    <DateRange
                        rangeValue={rangeValue}
                        setRangeValue={setRangeValue}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={(status) => setLoadingStatus(status)}
                        className="d-flex justify-content-end align-items-center"
                    />
                </div>
            </div>
            <Table thead={tableHead} tbody={tableBody} />
        </Card>
    )
}

export {Tickets}

type PropsDropDown = {
    description: string
}
const DropDown: FC<PropsDropDown> = ({description}) => {

    const ref = useRef<HTMLDivElement | null>(null)
    const [toggle, setToggle] = useState<boolean>(false)

    useClickInside(ref, () => {
        if (!toggle) setToggle(true)
    })

    useClickOutside(ref, () => {
        if (toggle) setToggle(false)
    })


    return (
        <div className="position-relative d-flex justify-content-center align-content-center" ref={ref}>
            <Whisper placement="top" controlId="control-id-click" trigger="hover"
                     speaker={<Tooltip>Voir la description</Tooltip>}>
                <div
                    className={`btn btn-sm btn-icon btn-color-info btn-active-light-info ${toggle ? 'show menu-dropdown' : ''}`}
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="top-end"
                >
                    <KTSVG path={`/media/icons/duotune/general/gen005.svg`}
                           className="svg-icon-3 svg-icon-success" />
                </div>
            </Whisper>

            <div
                className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-275px ${toggle ? 'show ' : ''}`}
                style={{
                    zIndex: 105,
                    position: 'absolute',
                    inset: '0 auto auto 0',
                    margin: 0,
                    top: '35px',
                }}
                data-kt-menu-placement="bottom-end"
                data-kt-menu="true"
            >
                <div className="menu-item px-2 bg-light-warning">
                    <div className="menu-content fs-7 text-dark fw-bolder px-2 py-4">Description</div>
                </div>
                <div className="separator mb-1 opacity-75" />

                <div className="d-flex flex-column p-1">
                    <div className="menu-item px-2 mb-1">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    );
};