import {FC, useEffect, useState} from 'react'
import {ChildrenInterface} from '../../../global'
import {initialState, UserContext, UserInterface} from './types'
import {useQuery} from 'react-query'
import SetupAxios from '../../../setup/axios/SetupAxios'

const GET_USER = process.env.REACT_APP_GET_USER || 'get_user'

const fetchData = async (endpoint: string): Promise<UserInterface> => {
    try {
        const response = await SetupAxios.get<UserInterface>(endpoint)
        const data = response.data

        // Save data to local storage with a timestamp
        localStorage.setItem('_userInterface', JSON.stringify({data, timestamp: Date.now()}))
        localStorage.setItem('_clientCreationDate', data.created_at)

        return data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error
    }
}

export const UserProvider: FC<ChildrenInterface> = ({children}) => {
    const [personalInformation, setPersonalInformation] = useState<UserInterface>(initialState)
    // const { isLoading, error, data } = useQuery('UserInfos', () => fetchData(GET_USER))

    // Load from localStorage initially
    useEffect(() => {
        const cachedData = localStorage.getItem('_userInterface')
        if (cachedData) {
            const parsedData = JSON.parse(cachedData).data
            setPersonalInformation(parsedData)
        }
    }, [])

    useQuery('UserInfos', () => fetchData(GET_USER), {
        onSuccess: (newData) => {
            setPersonalInformation(newData)
        },
    })

    return (
        <UserContext.Provider value={personalInformation}>
            {children}
        </UserContext.Provider>
    )
}

